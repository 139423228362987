<template>
  <el-card class="box-card" v-loading="loadingDownload" element-loading-text="Descargando Tabla..."
    element-loading-spinner="el-icon-loading" element-loading-background="rgb(255 255 255 / 80%)">
    <div slot="header" class="clearfix">
      <span> <i class="el-icon-user"></i> Lista de Jugadores </span>
    </div>
    <el-card class="card-shadow--small">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="Número de documento" size="mini" v-model="nit" clearable @clear="
            nit = null;
          getData();
          ">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="Email" size="mini" v-model="email" clearable @clear="
            email = null;
          getData();
          ">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input placeholder="Número de tabla" size="mini" v-model="table" clearable @clear="
            table = null;
          getData();
          ">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input placeholder="Código de tabla" size="mini" v-model="tableCode" clearable @clear="
          tableCode = null;
          getData();
          ">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" icon="el-icon-search" size="mini" :loading="loadingFilter"
            @click="filterTable">Buscar</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-table :data="tableData" v-loading="loading" height="400" style="width: 100%">
      <el-table-column prop="nit" label="Documento / NIT" width="130"></el-table-column>
      <el-table-column prop="name_player" label="Jugador"></el-table-column>
      <!-- <el-table-column prop="email" label="Email"></el-table-column> -->
      <el-table-column prop="phone" label="Teléfono" width="150"></el-table-column>
      <el-table-column label="Ciudad" width="200">
        <template slot-scope="scope">{{
          scope.row.city.description + " / " + scope.row.city.state.description
        }}</template>
      </el-table-column>
      <el-table-column label="Tabla" width="100">
        <template slot-scope="scope">{{
          scope.row.bingo.length > 0 ? scope.row.bingo[0].table_bingo : ""
        }}</template>
      </el-table-column>
      <el-table-column label="Código tabla" width="120">
        <template slot-scope="scope">{{
          scope.row.bingo.length > 0 ? scope.row.bingo[0].verify_code  : ""
        }}</template>
      </el-table-column>
      <el-table-column label="Pagó tabla" width="100">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.pay_table" :active-value="1" :inactive-value="0" active-color="#13ce66" @change="changePay($event, scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="Acciones" width="130" class="align-vertical-middle">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="Confirmar para generar tablas" placement="top">
            <el-button size="mini" type="success" class="" @click="confirmTable(scope.row)"
              :disabled="scope.row.bingo.length > 0 ? true : false" circle>
              <i class="el-icon-check"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Descargar tabla" placement="top">
            <el-button size="mini" type="primary" class="mr-10" @click="downloadTable(scope.row)" circle>
              <i class="el-icon-download"></i>
            </el-button>
          </el-tooltip>
          <el-popconfirm confirmButtonText="Si" cancelButtonText="No, Cancelar" icon="el-icon-info" iconColor="red"
            title="Deseas eliminar este registro?" @confirm="deleteTable(scope.row)">

            <!-- :disabled="scope.row.bingo.length > 0 ? true : false" -->
            <el-button slot="reference" size="mini" type="danger" circle>
              <i class="el-icon-delete"></i>
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="tableData && tableData.length > 0" class="pagination-box animated fadeInRight"
      style="text-align: end; padding-top: 10px">
      <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
        @current-change="handleCurrentPage"></el-pagination>
    </div>
  </el-card>
</template>
<script>
import { getPlayers, deletePlayer, downloadTable, changePayTable } from "@/api/player";
export default {
  name: "PlayersList",
  props: ["show"],
  watch: {
    show: function (val) {
      if (val) {
        this.getData();
      }
    },
  },
  data() {
    return {
      urlVerify: process.env.VUE_APP_ROOT + "jugar/verify/",
      tableData: [],
      loading: false,
      loadingFilter: false,
      loadingDownload: false,
      search: "",
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
      // filter
      nit: null,
      email: null,
      table: null,
      tableCode: null,
    };
  },
  methods: {
    changePay(data, row) {
      let me = this
      me.loading = true;
      changePayTable({ id: row.id, pay_table: data })
        .then(({ data }) => {
          this.$message({
            type: "success",
            message: "Registro actualizado con exito!",
          });
          me.loading = false;
        })
        .catch(function (error) {
          me.loading = false;
          me.$message.error("Error.");
          console.log(error);
        });
    },
    confirmTable(data) {
      console.log(data);
      window.open(this.urlVerify + data.id + '/' + data.confirmation_code);
    },
    downloadTable(row) {
      this.loadingDownload = true;
      downloadTable(row.bingo)
        .then(({ data }) => {
          // console.log(data);
          this.downloadFile(data, "Mis tablas de bingo");
        })
        .catch(function (error) {
          this.loadingDownload = false;
          this.$message.error("Error.");
          console.log(error);
        });
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
      this.loadingDownload = false;
    },
    filterTable() {
      this.loadingFilter = true;
      this.getData();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getData();
      console.log(val);
    },
    getData() {
      this.loading = true;
      if (this.nit != null || this.email != null || this.table != null || this.tableCode != null) {
        this.currentPage = 1;
      }
      getPlayers(
        this.pageSize,
        this.currentPage,
        this.nit,
        this.email,
        this.table,
        this.tableCode
      )
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loading = false;
          this.loadingFilter = false;
        })
        .catch(function (error) {
          this.loadingFilter = false;
          this.loading = false;
          console.log(error);
        });
    },
    deleteTable(row) {
      this.loading = true;
      deletePlayer(row.id)
        .then(({ data }) => {
          this.getData();
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    if (this.show) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scope>
.mr-10 {
  margin-right: 10px;
}
</style>